import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It was a bright summer night full of colorful noises of cars, mini planes, music, voices, and laughter in Moscow, 3021. A 13-year old boy Theo woke up screaming in fear as a nightmare scared him. Theo touched his wrist, a panel came out and he called a house robot Ven’ka to bring him some cocoa. While waiting, Theo pressed a button on his bed, and a small lamp turned on. Theo pressed another button and a mechanical arm brought him his favorite book “History Of Humanity.”  Theo was slowly sipping hot cocoa Ven’ka brought while reading about how Alexander Bell invented the phone. “Why do they need that ancient thing?” - he questioned himself – “Couldn’t they just teleport to talk and chat? Doesn’t quantum physics explain the process?”  Slowly reading about the inventor, Theo fell asleep. Good night, Moscow. Good night, Theo.`}</p>
    <p>{`In the morning, Theo woke up late-the nightmare’s effect. He quickly went to the intelligent wardrobe, and wardrobe chose clothes for Theo and dressed him up. Theo took a small cube from the freezer and heated it in a microwave-the cube turned into Theo’s favorite panini sandwich. Theo took his flyboard-and customized the panorama around him to Moscow’s New Year Night in 2020. No one else could see him, but he felt everything-he was like in an invisible capsule. Theo felt like he was a time traveler, even though he knew, it was just a virtual reality.  Theo saw a sparkling, beautiful city, full of decorations, lights, and Christmas trees. The air was filled with the odor of happiness, enjoyment, and satisfaction. Walking by the streets, he noticed how much his city was different-there were street lamps, cars, trucks, buses, trolleybuses, and something Theo liked the most-there were many old, big trees-live trees, something extinct in his time.  After a huge Tree Disaster, 99% of the trees were cut, and there was nothing green left in their world. Just recently, people started regrowing trees, but for now, all they could see were saplings. Suddenly, he understood he has to get home soon, and momentarily he was back in the real world from his virtual trip. While heading home, Theo understood how much harm humanity has caused our planet.`}</p>
    <p>{`When Theo came home, he went to his room and took out a beautiful, carved box. It was his great-great-grandpa’s. As he first touched it, a charming, enchanting warmth spread throughout his body. His great-great-grandfather collected tree seeds, and his collection was passed from dad to son.  “It’s time to use it”- decided Theo.  He grabbed some seeds and planted them. Delightful, Theo went to bed.  “Grow, trees. Save the planet’s beauty. Save us!”`}</p>
    <p>{`Seventy years later, grandpa Theo told the same story to his grandchildren while walking in the park before sleeping. They looked around-mother nature was everywhere around.” That is your work, Grandpa Theo? You saved the world?” asked children.”No,”- answered Theo -” We did.”`}</p>
    <p>{`Good night, trees. Good night, Theo. Good night, Moscow.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      